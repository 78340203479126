<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos del cliente
      </template>
      <v-expansion-panel-content>
        <ViewClienteData
          @changeComponent="changeComponent"
          :clienteInfo="clienteInfo"
          :canEdit="canEdit"
          v-if="switch_data == 1"
        >
        </ViewClienteData>
        <EditClienteData
          @changeTab="switch_data = 1"
          :clienteInfo="clienteInfo"
          @getData="$emit('getData'), switch_data = 1"
          v-else
        >
        </EditClienteData>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewClienteData from "./ViewClienteData.vue";
import EditClienteData from "./EditClienteData.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewClienteData,
    EditClienteData,
  },
  props: {
    clienteInfo: Object,
    routesConfigPassword: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },
};
</script>
