<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/cliente/update/' + $route.params.uuid"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col cols="12" md="6" lg="4" xl="4">
            <v-select
              label="Estatus del cliente"
              :items="estadoList"
              item-text="estado"
              item-value="id"
              v-model="formData.status"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Nombre(s)"
              v-model="formData.nombre"
              :rules="inputsRequeridos"
              v-number-only
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Apellido paterno"
              v-model="formData.apellidoPaterno"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Apellido materno"
              v-model="formData.apellidoMaterno"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col class="whitout-padding-bottom" cols="12" md="6" lg="4" xl="4">
            <span class="text-header-data whitout-padding-bottom ">
              Género
            </span>
            <v-radio-group
              v-model="formData.genero"
              row
              :rules="inputsRequeridos"
              style="padding-top: 0; margin-top: 0;"
            >
              <v-radio label="Femenino" :value="'F'" color="#00a7e4"></v-radio>
              <v-radio label="Masculino" :value="'M'" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              v-model="formData.fechaNacimiento"
              type="date"
              label="Fecha de Nacimiento"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              v-model="formData.edad"
              type="text"
              label="Edad"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              readonly
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Estado civil"
              outlined
              color="#00a7e4"
              v-model="formData.estadoCivil"
              :items="['Soltero(a)', 'Casado(a)']"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Agente"
              :items="agentes"
              outlined
              item-text="nombreCompleto"
              item-value="id"
              v-model="formData.agenteId"
              placeholder="Requerido"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
var moment = require("moment-timezone");
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommondForm,
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    clienteInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        id: null,
        status: 0,
        regimen: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        razonSocial: null,
        fechaConstitucion: null,
        rfc: null,
        correo: null,
        celular: null,
        telefonoOficina: null,
        agenteId: null,
        direccionFacturacionId: null,
        direccionResidenciaId: null,
        fechaCreacion: null,
        creadoPor: false,
        modificadoPor: false,
      },
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
      agentes: [],
      estadoList: [
        { id: 0, estado: "Nuevo" },
        { id: 1, estado: "Activo" },
        { id: 2, estado: "Inactivo" },
        { id: -1, estado: "Baja" },
      ],
    };
  },
  mounted() {
    this.fillData();
    this.getAgentes();
  },
  watch: {
    "formData.fechaNacimiento"(date) {
      const birthdayDate = moment(date);
      const today = moment();
      const years = today.diff(birthdayDate, "years");
      this.formData.edad = years;
    },
  },
  methods: {
    getAgentes() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/list`, config).then((response) => {
        this.agentes = response.data;
      });
    },
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (typeof this.clienteInfo[e] != 'undefined') {
          this.formData[e] = this.clienteInfo[e];
        }
      });
      this.formData.status = this.clienteInfo.status
    },
  },
};
</script>
