<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="changeComponent"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Estatus
          </span>
          <br />
          <v-btn rounded dark style="background-color: #f1c21b;" :elevation="0">
            <span>
              {{ 
                getStatus(clienteInfo.status)
              }}
            </span>
          </v-btn>
        </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nombre(s)
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.nombre ? clienteInfo.nombre : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido paterno
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.apellidoPaterno
              ? clienteInfo.apellidoPaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido materno
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.apellidoMaterno
              ? clienteInfo.apellidoMaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Género
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.genero
              ? getGeneroName(clienteInfo.genero)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha de nacimiento
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.fechaNacimiento
              ? getFormattHour(clienteInfo.fechaNacimiento)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Edad
        </span>
        <br />
        <span class="text-data-details">
          {{ getEdad(clienteInfo.fechaNacimiento) }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Estado Civil
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.estadoCivil ? clienteInfo.estadoCivil : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Agente
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.agenteId
              ? getAgenteName(clienteInfo.agenteId)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {},

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      agentes: [],
    };
  },
  props: {
    clienteInfo: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getAgentes();
  },
  methods: {
    getGeneroName(val) {
      return val == "M" ? "Masculino" : "Femenino";
    },
    getFormattHour(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
    getEdad(date) {
      const birthdayDate = moment(date);
      const today = moment();
      console.log("aqui ", birthdayDate, today);
      const years = today.diff(birthdayDate, "years");
      return years;
    },
    changeComponent() {
      this.$emit("changeComponent", 2);
    },
    getAgentes() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/list`, config).then((response) => {
        this.agentes = response.data;
      });
    },
    getAgenteName(id) {
      var agente = this.agentes.find((e) => e.id === id);
      return agente ? agente.nombreCompleto : "No disponible";
    },
    getStatus(val) {
      switch (val) {
        case 0:
          return "Nuevo";
        case 1:
          return "Activo";
        case 2:
          return "Inactivo";
        case 3:
          return "Baja";
        default:
          return "Cancelada";
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
